import request from '../utils/request';
import { REST_SERVICE } from '../config';

// 查询 我的申请
export const queryTaxFinish = (params) =>
  request({
    url: REST_SERVICE.myTax.queryTaxFinish,
    method: 'get',
    params,
  });
//列表
export const getTaxInfoList = (params) =>
  request({
    url: REST_SERVICE.myTax.getTaxInfoList,
    method: 'get',
    params,
  });
// 获取二维码
export const getTaxQrCode = (params) =>
  request({
    url: REST_SERVICE.myTax.getTaxQrCode,
    method: 'get',
    params,
  });
// 推送税务
export const pushTax = (params) =>
  request({
    url: REST_SERVICE.myTax.pushTax,
    method: 'get',
    params,
  });
// 驳回信息查询
export const quryJkxxOrBhxx = (params) =>
  request({
    url: REST_SERVICE.myTax.quryJkxxOrBhxx,
    method: 'get',
    params,
  });
