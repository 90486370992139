<template>
  <div>
    <div class="top" v-show="!showDetail">
      <e-search
        ref="search"
        style="padding: 8px 0"
        :filterGroup="filterGroup"
        :btnsConfig="btnsConfig"
        :EDialogConfig="EDialogConfig"
        @search="search"
        @reset="reset"
        @exportExcel="exportExcel"
      ></e-search>
      <e-table
        ref="myApplicationRef"
        :loading="loading"
        :tableList="tableList"
        :columns="columns"
        :operates="operates"
        :options="options"
        @afterCurrentPageClick="afterCurrentPageClickHandle"
      ></e-table>
    </div>
    <div class="center" v-show="showDetail">
      <application-detail
        v-if="showDetail"
        @back="back"
        :formModal="formModal"
        :config="config"
        :disabled="true"
      ></application-detail>
    </div>
    <detail ref="auditDetailsRef"></detail>
    <klmypay ref="klmyPay" />
    <payDialog ref="payDialog"></payDialog>
    <e-dialog :config="openCertConfig" :btns="openCertBtns">
      <template slot="content">
        <e-table :tableList="certTableList" :options="certOptions" :operates="certOperates" :columns="certColumns" />
      </template>
    </e-dialog>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import ESearch from '@/components/e-search';
import ETable from '@/components/e-table';
import { localDataPermission } from '@iebdc/utils/local-data';
import {
  getProcessInfoPageList,
  deleteProcessInfo,
  retractApply,
  exportwdsqExcel,
  printApply,
  estatePrintApply,
  printCQZM,
  // printDyzm,
  getPageUrl,
  getProcessInfo,
  saveEvaluateStatus,
} from 'iebdc/api/my-application.js';
import payOnline from '@iebdc/api/pay-online.js';
import { IEBDC_USER_DATA } from '@iebdc/config/app-data';
import utils from '@/utils/utils';
import hasPermission from '@iebdc/mixin/hasPermission';
import ApplicationDetail from './application-detail';
import processInfo from '@iebdc/mixin/getProcessInfo';
import { format } from 'date-fns';
import { localDataUser } from 'src/pages/iebdc/utils/local-data';
import { downloadCeritificate } from '@/pages/iebdc/api/electronic-license';
import { getRandom } from '../../../api/login';
import detail from './el-dialog/detail';
import payDialog from '@iebdc/components/pay-dialog/index';
import klmypay from './el-dialog/KLMY-pay.vue';

var noPayCode = ['F400104', 'F400103', 'F999001', 'F400301', 'F100201', 'F100211', 'F100203', 'F300126', 'F360000'];
export default {
  name: 'myApplication',
  mixins: [hasPermission, processInfo],
  props: ['stateBoxData', 'dictype'],
  components: {
    ESearch,
    ETable,
    ApplicationDetail,
    detail,
    payDialog,
    klmypay,
  },
  data() {
    return {
      loading: false,
      params: {},
      tabelIndex: '1',
      userInfo: localDataUser.get() || {},
      firstQueryParamMap: ['wwywh', 'qlrmc', 'ywrmc', 'processName'],
      showDetail: false,
      detailData: {}, //查看权利人信息细节
      EDialogConfig: {
        visible: false, //默认不显示更多
        maxLength: 5,
        name: 'applicationMain',
      },
      btnsConfig: {
        clearBtnText: '清除',
        showResetBtn: true,
        showExportBtn: true,
        showMoreBtn: false,
      },
      dropdownList: [
        {
          label: '打印申请表',
          show: (row) => {
            return row.step !== 'NETAPPLYING' && this.hasPermission('IEBDC:DYQX:DYSQB');
          },
          dropdownItemclick: (row) => {
            printApply({ ywh: row.wwywh }).then((res) => {
              if (res.success === true) {
                window.open(res.data);
              } else {
                this.$message.error('打印失败');
              }
            });
          },
        },
        {
          label: '打印受理凭证',
          show: (row) => {
            return row.step !== 'NETAPPLYING' && this.hasPermission('IEBDC:DYQX:DYSLPZ');
          },
          dropdownItemclick: (row) => {
            estatePrintApply({ ywh: row.wwywh }).then((res) => {
              if (res.success === true) {
                window.open(res.data);
              } else {
                this.$message.error('打印失败');
              }
            });
          },
        },
        {
          label: '打印电子证照',
          show: (row) => {
            return (row.step === 'GD' || row.step === 'COMPLETE') && this.hasPermission('IEBDC:DYQX:DYDYZM');
          },
          dropdownItemclick: (row) => {
            if (row.nwbdcqzhs) {
              let djjgmc = this.changeDjjg(row.djjg);
              let cqzList = row.nwbdcqzhs.split(',');
              if (cqzList.length === 1) {
                this.downCert(cqzList[0], row.djjg);
              } else {
                cqzList.forEach((item) => {
                  let cqzObj = {
                    qlrmc: row.qlr,
                    djjg: djjgmc,
                    bdcqzh: item,
                  };
                  this.certTableList.push(cqzObj);
                });
                this.openCertConfig.visible = true;
              }
            } else {
              this.$message.warning('暂无产权证可打印！');
            }
          },
        },
        {
          label: '打印产权证明',
          show: (row) => {
            return row.step === 'AWARD';
          },
          dropdownItemclick: (row) => {
            printCQZM({ ywh: row.wwywh }).then((res) => {
              if (res.success === true) {
                window.open(res.data);
              } else {
                this.$message.error('打印失败');
              }
            });
          },
        },
      ],
      filterGroup: [
        {
          type: 'select',
          options: [
            { label: '业务号', value: 1 },
            { label: '权利人', value: 2 },
            { label: '义务人', value: 3 },
            { label: '业务名称', value: 4 },
          ],
          value: 1,
        },
        { type: 'input', placeholder: '业务号', value: '' },
        {
          label: '申请时间',
          type: 'dateRange',
          centerLabel: false,
          options: [
            {
              key: 'sqkssj',
              value: '',
              valueformat: 'yyyy-MM-dd',
              placeholder: '申请时间起',
            },
            {
              key: 'sqjssj',
              value: '',
              valueformat: 'yyyy-MM-dd',
              placeholder: '申请时间止',
            },
          ],
        },
        {
          type: 'dicSelect',
          dicType: '办理状态',
          label: '办理状态',
          placeholder: '办理状态',
          key: 'step',
          value: '',
        },
      ],
      tableList: [],
      columns: [
        {
          width: '200px',
          fixed: true,
          prop: 'wwywh',
          label: '业务号',
          align: 'center',
        },

        {
          width: '300px',
          prop: 'zl',
          label: '坐落',
          align: 'center',
        },
        {
          width: '180px',
          prop: 'subFlowName',
          label: '业务名称',
          align: 'center',
        },
        {
          width: '100px',
          prop: 'sqsj',
          align: 'center',
          label: '申请时间',
          type: 'date',
          formatter: (scope) => {
            return utils.dateFtt(scope.ctime || scope.sqsj, 'yyyy-MM-dd');
          },
        },
        {
          width: '120px',
          prop: 'step',
          align: 'center',
          label: '办理状态',
          // type: 'dic',
          formatter: (scope) => {
            const dicLabel = this.formatDic('办理状态', scope.step);
            if (scope.payStat === 1) {
              return `<span>缴费（已缴费）</span>`;
            } else if (scope.payStat === 2) {
              return `<span>缴费（缴费中）</span>`;
            } else {
              return dicLabel;
            }
          },
        },
        {
          width: '160px',
          prop: 'qlr',
          label: '权利人',
          align: 'center',
        },
        {
          width: '160px',
          prop: 'ywrmc',
          label: '义务人',
          align: 'center',
        },
        {
          width: '160px',
          label: '缴费状态',
          align: 'center',
          render: (h, row) => {
            let that = this;
            let time = 'ID' + new Date().getTime();
            return h('pay-order', {
              props: {
                row: row,
                timestamp: time.substring(0, time.length - 2) + '00',
              },
            });
          },
        },
        {
          width: '250',
          prop: 'billNo',
          label: '缴款码',
          align: 'center',
          render: (h, row) => {
            // console.log(row);
            // if (row.billNoList && row.billNoList.length > 0)
            return h('bill-no', {
              props: {
                row: row,
              },
            });
            // else {
            //   return h('span', {
            //     domProps: {
            //       innerHTML: '-',
            //     },
            //   });
            // }
          },
        },
      ],
      operates: {
        //表格操作栏
        width: '210px',
        fixed: 'right',
        align: 'center',
        list: [
          {
            show: true,
            disabled: (row) => {
              if (row.step === 'NETAPPLYING' || row.step === 'VERIFYSUCCESS') {
                return true;
              }
            },
            spanType: 'button',
            id: '1',
            label: '查看',
            type: 'text',
            color: 'primary',
            size: 'medium',
            method: (key, row) => {
              this.getDetail(row.wwywh);
            },
            showCallback: () => {
              return true;
            },
          },
          {
            show: true,
            spanType: 'button',
            id: '2',
            label: '编辑',
            type: 'text',
            size: 'medium',
            color: 'warning',
            method: (key, row) => {
              this.updateFirstFlow({
                name: row.subFlowName,
                netFlowdefCode: row.netFlowdefCode,
              });
              localStorage.setItem('ywh', row.wwywh);
              this.$router.push({
                name: 'applicantTable',
                params: { ywh: row.wwywh },
              });
            },
            showCallback: (row) => {
              if (
                row.step === 'PRVERIFING' ||
                row.step === ' PRVERIFY_PASSED' ||
                row.step === 'PRVERIFY_NOTPASS' ||
                row.step === 'NETAPPLYING' ||
                row.step === 'VERIFICATION'
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          {
            show: true,
            spanType: 'button',
            id: '3',
            label: '重新申请',
            type: 'text',
            size: 'medium',
            color: 'info',
            method: (key, row) => {
              this.updateFirstFlow({
                name: row.subFlowName,
                netFlowdefCode: row.netFlowdefCode,
              });
              localStorage.setItem('ywh', row.wwywh);
              this.$router.push({
                name: 'applicantTable',
                params: { ywh: row.wwywh },
              });
            },
            showCallback: (row) => {
              if (this.hasPermission('IEBDC:WDSQ:ZCSQ') && (row.step === 'NETNOPASS' || row.step === 'REJECT')) {
                return true;
              } else {
                return false;
              }
            },
          },
          {
            show: true,
            spanType: 'button',
            id: '4',
            label: '撤回',
            type: 'text',
            size: 'medium',
            color: 'primary',
            method: (key, row) => {
              this.$confirm('是否撤回？', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(() => {
                retractApply({ id: row.qlxxId }).then((res) => {
                  if (res.success === true) {
                    this.$message.success('撤回成功！');
                    this.query();
                  } else {
                    this.$message.error(res.message);
                  }
                });
              });
            },
            showCallback: (row) => {
              if (row.step === 'NETCHECKING') {
                return true;
              } else {
                return false;
              }
            },
          },
          {
            show: true,
            spanType: 'button',
            id: '5',
            label: '删除',
            type: 'text',
            size: 'medium',
            color: 'danger',
            method: (key, row) => {
              this.$confirm('是否删除？', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(() => {
                deleteProcessInfo({ wwywh: row.wwywh }).then((res) => {
                  if (res.success === true) {
                    this.$message.success('删除成功！');
                    this.query();
                  } else {
                    this.$message.error(res.message);
                  }
                });
              });
            },
            showCallback: (row) => {
              if (
                row.step !== 'NETAPPLYING' &&
                row.step !== 'ACCEPTANCE' &&
                row.step !== 'CHECKING' &&
                row.step !== 'SAMPLING' &&
                row.step !== 'APPROVAL' &&
                row.step !== 'REGISTERING' &&
                row.step !== 'CERTIFICATING' &&
                row.step !== 'PAYING' &&
                row.step !== 'AWARD' &&
                row.step !== 'GD' &&
                row.step !== 'NETPASSED' &&
                row.step !== 'COMPLETE'
              ) {
                return true;
              } else {
                return false;
              }
              // if (
              //   row.step === "NETAPPLYING" ||
              //   row.step === "NETNOPASS" ||
              //   row.step === "VERIFICATION" ||
              //   row.step === "VERIFYSUCCESS"
              // ) {
              //   return true;
              // } else {
              //   return false;
              // }
            },
          },
          {
            show: true,
            spanType: 'dropdown',
            id: '6',
            label: '打印',
            type: 'text',
            size: 'medium',
            color: 'warning',
            dropdownList: [],
            method: () => {
              this.operates.list[5].dropdownList = this.dropdownList;
            },
            showCallback: (row) => {
              if (
                (row.step !== 'NETAPPLYING' && row.step !== 'VERIFICATION' && row.step !== 'VERIFYSUCCESS') ||
                (row.step === 'PAYING' && IEBDC_USER_DATA().institutionalUser.name)
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          // {
          //   id: '7',
          //   show: true,
          //   spanType: 'button',
          //   label: '评价',
          //   type: 'text',
          //   size: 'medium',
          //   color: 'success',
          //   disabled: (row) => {
          //     if (row.evaluateStatus === 1) {
          //       return true;
          //     } else if (row.evaluateStatus === 0) {
          //       return false;
          //     }
          //   },
          //   method: (key, row) => {
          //     // console.log('评价');
          //     // console.log('row--->>>', row);
          //     // console.log('userInfo--->>>', userInfo);
          //     const params = {
          //       backUrl: window.CONFIG.BACK_URL + '?ywh0=' + row.wwywh,
          //       deptName: window.CONFIG.APP_INFO.deptName,
          //       pjParams: {
          //         taskName: row.flowName, // 事项名称
          //         userName: this.userInfo.name, // 用户名
          //         phoneNumber: this.userInfo.tel, //联系电话
          //         userPageType: this.userInfo.zjzl ? '111' : '001', // 申请人证件类型
          //         userProp: this.userInfo.userCategory === 'NORMAL' ? '1' : '2', // 用户属性 （1-个人 2-法人）
          //         acceptDate: format(row.sqsj, 'yyyy-MM-dd HH:mm:ss'), // 收件时间 提交完成时间
          //         certKey: this.userInfo.zjh || this.userInfo.qyzjh, // 申请人证件号码
          //         pf: '1', // 评价渠道
          //         subMatter: '', // 事项主题非必填
          //         promisetime: '1', // 办件类型 （1-承诺件 2-即办件）
          //         proStatus: this.getProStatus(row.step), // 办理状态 （待受理=1，已受理=2，已办结=3）
          //         handleUserName: this.userInfo.name, // 联系人名称 （代理人） 非必填
          //         handleUserPageCode: this.userInfo.zjh, // 联系人证件号码 （代理人） 非必填
          //         handleUserPageType: this.userInfo.zjzl ? '111' : '001', //联系人证件类型 （代理人） 非必填
          //         proDepart: '', // 受理部门名称 （办理机构)
          //         taskCode: '', // 实施编码
          //         taskHandleItem: '', // 业务办理项编码
          //         projectNo: '', // 办件编号 后台生成
          //         deptCode: window.CONFIG.APP_INFO.deptCode, // 统一信用社代码
          //         pjsjly: window.CONFIG.APP_INFO.pjsjly
          //       },
          //       ywh: row.wwywh
          //     };
          //     getProcessInfo({ wwywh: row.wwywh }).then((res) => {
          //       if (res.data && res.success) {
          //         // console.log(res);
          //         params.pjParams.taskCode = res.data.taskCode || '';
          //         params.pjParams.taskName = res.data.pjlcmc || ''; // 事项名称
          //         params.pjParams.taskHandleItem = res.data.taskHandleItem || '';
          //         params.pjParams.projectNo = res.data.projectNo || '';
          //         params.pjParams.proDepart = res.data.djjgmc || '';
          //         if (!params.pjParams.taskName) {
          //           params.pjParams.taskName = res.data.flowname || '';
          //         }
          //         getPageUrl(JSON.stringify(params)).then((res) => {
          //           window.open(res.data, '_blank');
          //         });
          //       }
          //     });
          //   },
          //   showCallback: (row) => {
          //     const dsl = ['NETAPPLYING', 'NETCHECKING', 'VERIFICATION', 'VERIFYSUCCESS', 'NETPASSED', 'NETNOPASS', 'REJECT'];
          //     if (dsl.indexOf(row.step) < 0) {
          //       return true;
          //     } else {
          //       return false;
          //     }
          //   }
          // },
          //   {
          //     id: '7',
          //     show: true,
          //     spanType: 'button',
          //     label: '生成缴费单',
          //     type: 'text',
          //     size: 'medium',
          //     color: 'danger',
          //     method: (index, row) => {
          //       this.$refs.auditDetailsRef.open(row);
          //     },
          //     showCallback: (row) => {
          //       if (noPayCode.indexOf(row.flowCode) > -1) {
          //         return false;
          //       } else if (row.step === 'REGISTERING' || row.step === 'CERTIFICATING' || row.step === 'CERTIFICATING') return true;
          //       return true;
          //     },
          //   },
          {
            id: '10',
            show: true,
            spanType: 'button',
            label: '缴费',
            type: 'text',
            size: 'medium',
            color: 'danger',
            method: (index, row) => {
              if (
                row.zl.indexOf('克拉玛依') > 0
                //&&
                // (row.step === 'REGISTERING' ||
                //   row.step === 'CERTIFICATING' ||
                //   row.step === 'PAYING' ||
                //   row.step === 'AWARD' ||
                //   row.step === 'GD' ||
                //   row.step === 'COMPLETE'
              )
                this.$refs.klmyPay.getEwm(row);
              else {
                this.$confirm('缴费后的第二天可以出具电子缴费凭证!!', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                  .then(() => {
                    payOnline.selectPayApplyPageList({ orderNO: row.wwywh }).then((res) => {
                      if (res.success) {
                        if (res.data.page.length !== 0) {
                          this.goOnlinePay(res.data.page);
                        } else {
                          this.$message.warning('请联系后台审核人员进行缴费审核');
                        }
                      }
                    });
                  })
                  .catch(() => {});
              }
            },
            showCallback: (row) => {
              if (
                //缴费审核完成并且流程状态在登簿节点以后才可缴费
                row.isShowPay === true
                //&&
                // (row.step === 'REGISTERING' ||
                //   row.step === 'CERTIFICATING' ||
                //   row.step === 'PAYING' ||
                //   row.step === 'AWARD' ||
                //   row.step === 'GD' ||
                //   row.step === 'COMPLETE')
              )
                return true;
              //   if (noPayCode.indexOf(row.flowCode) > -1) {
              //     return false;
              //   } else if (row.step === 'REGISTERING' || row.step === 'CERTIFICATING' || row.step === 'CERTIFICATING') return true;

              return false;
            },
          },
          {
            id: '8',
            show: true,
            spanType: 'button',
            label: '材料上传',
            type: 'text',
            size: 'medium',
            color: 'success',
            method: (key, row) => {
              this.updateFirstFlow({
                name: row.subFlowName,
                netFlowdefCode: row.netFlowdefCode,
              });
              localStorage.setItem('ywh', row.wwywh);
              this.$store.commit('wysq-step/getStepList', {
                code: row.netFlowdefCode,
                step: row.step,
              });
              getProcessInfo({ wwywh: row.wwywh }).then((res) => {
                // 需要更新的数据对象
                const params = {
                  id: res.data.id,
                  wwywh: res.data.wwywh,
                  ywh: res.data.ywh,
                  subcode: res.data.subFlowcode,
                  subname: res.data.subFlowname,
                  code: res.data.netFlowdefCode,
                  sqrlx: res.data.sqrlx,
                };
                // 调用vuex，更新数据对象
                this.setFlowInfoIng(params);
              });
              this.$router.push({
                name: 'uploadFile',
                params: { flowCode: row.netFlowdefCode, ywh: row.wwywh },
              });
            },
            showCallback: (row) => {
              return false;
              // if (!localDataPermission.get().includes('IEBDC:SQ:SQRZ')) {
              //   return false;
              // } else {
              //   return (
              //     row.step === 'NETAPPLYING' ||
              //     row.step === 'REJECT' ||
              //     row.step === 'VERIFICATION' ||
              //     row.step === 'VERIFYSUCCESS'
              //   );
              // }
            },
          },
          {
            id: '9',
            show: true,
            spanType: 'button',
            label: '驳回原因',
            type: 'text',
            size: 'medium',
            color: 'danger',
            method: (key, row) => {
              let yy = row.nwerror || '暂无驳回原因';
              this.$alert(yy, '驳回原因', {
                confirmButtonText: '确定',
                callback: () => {},
              });
            },
            showCallback: (row) => {
              return row.step === 'REJECT';
            },
          },
        ],
      },
      options: {
        // 每页数据数
        pageSize: 10,
        // 当前页码
        currentPage: 1,
        loading: true,
        mutiSelect: false,
        hasIndex: false,
        // marginTop: '10px',
        maxHeight: '5000px',
        showTip: false,
      },
      openCertConfig: {
        title: '不动产权列表',
        width: '900px',
        visible: false,
        hasConfirm: false,
        hasCancel: false,
        hasBtn: true,
      },
      openCertBtns: [
        {
          label: '返回',
          class: 'public-el-button-default',
          method: () => {
            this.openCertConfig.visible = false;
          },
          show: true,
        },
      ],
      certTableList: [],
      certOptions: {
        hasIndex: true,
        height: '320px',
        maxHeight: '556px',
        hasPagination: false,
      },
      certColumns: [
        {
          prop: 'qlrmc',
          label: '权利人名称',
        },
        {
          prop: 'djjg',
          label: '登记机构',
          width: 80,
        },
        {
          prop: 'bdcqzh',
          label: '不动产权证号',
          width: 210,
        },
      ],
      certOperates: {
        //表格操作栏
        width: 80,
        fixed: 'right',
        list: [
          {
            id: '1',
            label: '打印',
            show: true,
            spanType: 'button',
            type: 'text',
            size: 'medium',
            color: 'primary',
            method: (key, row) => {
              this.downCert(row.bdcqzh, row.djjg);
            },
            showCallback: () => {
              return true;
            },
          },
        ],
      },
    };
  },
  computed: {
    firstSelectVal() {
      return this.filterGroup[0].value;
    },
    ...mapGetters(['selectedOrganizationsTitle']),
  },
  watch: {
    firstSelectVal: {
      handler: function (newVal) {
        if (newVal) {
          // 设置 placeholder
          this.filterGroup[1].placeholder = this.filterGroup[0].options[newVal - 1].label;
          // 设置 key
          this.filterGroup[1].key = this.firstQueryParamMap[newVal - 1];
          // 置空value
          this.filterGroup[1].value = '';
        }
      },
      immediate: true,
    },
    stateBoxData: {
      handler: function (newVal) {
        if (newVal.hasOwnProperty('stepList')) {
          this.filterGroup[3].dicType = this.dictype;
          this.$refs.search.reset();
        }
      },
      deep: true,
    },
  },

  methods: {
    //更新流程名称
    ...mapMutations('wysq-data', ['setFlowName']),
    // 更新四级选择数据
    ...mapMutations('wysq-data', ['updateFirstFlow']),
    // 更新流程信息
    ...mapMutations('wysq-data', ['setFlowInfoIng']),
    // 改变步骤条状态
    ...mapMutations('wysq-step', ['targetToStep']),
    //字典格式化
    formatDic(column, prop) {
      const dicLabel = this.$store.getters.getLabelDicByTypeAndValue(column, prop);
      return dicLabel;
    },
    // 初始化数据
    init() {
      this.params = {
        nCurrent: 0,
        userId: IEBDC_USER_DATA().id,
      };
      this.query();
    },
    query() {
      this.loading = true;
      this.showDetail = false;
      let queryData = {};
      if (this.stateBoxData && this.stateBoxData.hasOwnProperty('stepList')) {
        queryData = Object.assign(this.params, this.stateBoxData);
      } else if (this.stateBoxData && !this.stateBoxData.hasOwnProperty('stepList')) {
        return;
      } else {
        queryData = this.params;
      }
      getProcessInfoPageList(queryData).then((res) => {
        this.loading = false;
        if (res.success && res.data) {
          //初始化缴费状态
          res.data.page.forEach((element) => {
            element.isShowPay = false;
            element.billNo = '-';
          });
          this.$refs.myApplicationRef.setTableList(res.data.page);
          this.$refs.myApplicationRef.setTotal(res.data.recordCount);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 查询
    search(queryParams) {
      this.params = Object.assign(this.params, queryParams, { nCurrent: 0 });
      this.query();
      this.$refs.myApplicationRef.setCurrentPage(1);
    },
    // 清除(重置)
    reset() {
      //将 第一个select 置为默认显示的值
      this.filterGroup[0].value = 1;
      this.init();
      this.$refs.myApplicationRef.setCurrentPage(1);
    },
    goOnlinePay(data) {
      this.$refs.payDialog.open(data);
    },

    // 导出
    exportExcel() {
      let time = utils.dateFtt(new Date(), 'yyyy-MM-dd');
      // let userId = IEBDC_USER_DATA.id;
      let fileName = '登记申请基本信息';
      exportwdsqExcel(this.params)
        .then((res) => {
          let blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          });
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName + time + '.xlsx');
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName + time;
            link.click();
            window.URL.revokeObjectURL(link.href);
            link.remove();
          }
        })
        .catch((err) => {
          this.$message({
            message: err,
            type: 'warning',
          });
        });
    },
    // 翻页
    afterCurrentPageClickHandle(val) {
      console.log(val);
      this.params.nCurrent = val - 1;
      this.query();
    },
    // 删除数组指定元素返回新数组且不改变原数组
    remove(arr, item) {
      let result = [];
      arr.forEach((element) => {
        if (element !== item) {
          result.push(element);
        }
      });
      return result;
    },
    //查看
    async getDetail(wwywh) {
      // 获取流程数据
      Promise.all([this.getProcessInfo(wwywh)])
        .then(() => {
          // 需要更新的数据对象
          const params = {
            id: this.formModal.id,
            wwywh: this.formModal.wwywh,
            sqrlx: this.formModal.sqrlx,
          };
          // 调用vuex，更新数据对象
          this.setFlowInfoIng(params);
          this.showDetail = true;
        })
        .catch((errMsg) => {
          this.$message.error(errMsg);
        });
    },
    //返回
    back() {
      this.showDetail = false;
      // console.log(this.detailData);
    },
    //获取当前状态对应值
    getProStatus(step) {
      const dsl = ['NETCHECKING', 'NETPASSED', 'NETNOPASS', 'VERIFICATION', 'VERIFYSUCCESS'];
      const ysl = ['ACCEPTANCE', 'CHECKING', 'REGISTERING', 'CERTIFICATING', 'PAYING', 'AWARD'];
      const ybl = ['COMPLETE', 'GD'];
      if (dsl.indexOf(step) > -1) {
        return '1';
      } else if (ysl.indexOf(step) > -1) {
        return '2';
      } else if (ybl.indexOf(step) > -1) {
        return '3';
      }
    },
    //评价状态维护
    saveStatus() {
      if (!this.$route.params.ywh) {
        this.init();
        return;
      }
      saveEvaluateStatus({ ywh: this.$route.params.ywh }).then((res) => {
        // console.log(res);
        if (res.data && res.success) {
          this.init();
          this.$message('评价成功!');
        } else {
          this.$message('评价失败!');
        }
      });
    },
    //base64 转换 blob
    dataURLtoBlob(dataurl) {
      let bstr = atob(dataurl);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: 'pdf' });
    },
    // 下载电子证照
    downCert(bdcqzh, djjg) {
      const params = {
        djjg: djjg,
        ip: '',
        yhm: '',
        zh: bdcqzh || '',
      };
      downloadCeritificate(JSON.stringify(params)).then((res) => {
        if (res.data && res.success) {
          if (res.data.data) {
            let URL = this.dataURLtoBlob(res.data.data[0].zzxx);
            let filenamePDF = bdcqzh ? bdcqzh + '.pdf' : '不动产权证' + '.pdf';
            let reader = new FileReader();
            reader.readAsDataURL(URL);
            reader.onload = function (e) {
              // 兼容IE
              if (window.navigator.msSaveOrOpenBlob) {
                let bstr = atob(e.target.result.split(',')[1]);
                let n = bstr.length;
                let u8arr = new Uint8Array(n);
                while (n--) {
                  u8arr[n] = bstr.charCodeAt(n);
                }
                let blob = new Blob([u8arr]);
                window.navigator.msSaveOrOpenBlob(blob, filenamePDF);
              } else {
                // 转换完成，创建一个a标签用于下载
                const a = document.createElement('a');
                a.download = filenamePDF; // 这里写你的文件名
                a.href = e.target.result;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }
            };
          } else {
            this.$message.warning('暂无电子证照！');
          }
        } else {
          this.$message.error('获取电子证照失败！');
        }
      });
    },
    // 获取登记机构名称
    changeDjjg(code) {
      let djjgmc = '';
      let areaDataLocal = JSON.parse(localStorage.finalAreaDataLocal);
      let allCity = areaDataLocal[0].childrens;
      allCity.forEach((item) => {
        if (item.code.indexOf(code.substring(0, 4)) >= 0) {
          let allCountries = item.childrens;
          allCountries.forEach((country) => {
            if (country.code === code) {
              djjgmc = country.bsdtData[0].jgmc;
            }
          });
        }
      });
      return djjgmc;
    },
  },
  mounted() {
    console.log(this.stateBoxData);

    if (this.stateBoxData) {
      this.query();
      return;
    }
    this.saveStatus();
  },
};
</script>
<style lang="scss" scoped>
/deep/ .filter-item {
  margin: 0 !important;
}

.jdcx-center {
  margin-top: 20px;

  label {
    font-size: 16px;
    float: left;
    line-height: 80px;
    margin-right: 30px;
    font-weight: 700;
  }

  .jdcx-item {
    /*width: 130px;*/
    height: 80px;

    .jd-jt {
      width: 50px;
      line-height: 80px;
      text-align: center;
    }

    .jd-img {
      width: 80px;
      height: 80px;
      font-size: 13px;
      background-color: #f3f3f3;
      border-radius: 5px;
      text-align: center;

      div {
        height: 48px;
        margin-bottom: 3px;
      }
    }
  }
}
/deep/ .e-table {
  position: relative !important;
  .el-table__body-wrapper {
    height: auto !important;
  }
}
/*
/deep/ .el-button--primary {
  color: #409EFF !important;
  background: #ecf5ff !important;
  border-color: #b3d8ff !important;
  &:hover {
    color: #FFF !important;
    background-color: #409EFF !important;
    border-color: #409EFF !important;
  }
  /depe/ .is-plain {
    /deep/ .is-disabled {
      color: #8cc5ff !important;
      background-color: #ecf5ff !important;
      border-color: #d9ecff !important;
      pointer-events: none;
    }
  }
} */
</style>
