<template>
  <div>
    <div class="top" v-show="!showDetail">
      <e-search
        ref="search"
        style="padding: 8px 0"
        :filterGroup="filterGroup"
        :btnsConfig="btnsConfig"
        @search="search"
        @reset="reset"
      ></e-search>
      <e-table
        ref="myApplicationRef"
        :loading="loading"
        :tableList="tableList"
        :columns="columns"
        :operates="operates"
        :options="options"
        @afterCurrentPageClick="afterCurrentPageClickHandle"
      ></e-table>
    </div>
    <e-dialog :config="openRightInfo" :btns="openAddBtns">
      <template slot="content">
        <el-table class="el-table-yx" :header-cell-style="{ fontSize: '300', color: '#555' }">
          <el-table-column prop="taxType" label="税费种类"> </el-table-column>
          <el-table-column prop="taxSubject" label="税费科目名称"> </el-table-column>
          <el-table-column prop="taxAmount" label="计税金额(元)"> </el-table-column>
          <el-table-column prop="axRate" label="税率"> </el-table-column>
          <el-table-column prop="taxPayable" label="应纳税额"> </el-table-column>
          <el-table-column prop="exemptions" label="减免税额"> </el-table-column>
          <el-table-column prop="reductionReason" label="减税原因"> </el-table-column>
          <el-table-column prop="taxpayer" label="承受方名称"> </el-table-column>
          <el-table-column prop="status" label="状态"> </el-table-column>
        </el-table>
        <table class="yxtable">
          <tr>
            <th>收费人：</th>
            <!-- <td>{{ dataList[0].payee }}</td> -->
            <td></td>
            <th>实收金额(元)：</th>
            <!-- <td>{{ dataList[0].taxPayment }}</td> -->
            <td></td>
          </tr>
          <tr>
            <th>完税凭证：</th>
            <!-- <td>{{ dataList[0].taxPaymentCertificate }}</td> -->
            <td></td>
            <th>收费日期：</th>
            <!-- <td>{{ dataList[0].paidTime }}</td> -->
            <td></td>
          </tr>
        </table>
      </template>
    </e-dialog>
    <e-dialog :config="openZYDJInfo" :btns="openZYDJBtns" class="zydj-dia">
      <template slot="content">
        <div v-for="(zydjData, index) in zydjDataList" :key="index">
          <div v-if="!zydjData.flag">
            <div v-if="qlrList">
              <div class="zydj-block">
                <!-- <i class="el-icon-user-solid"></i> -->
                <img :src="require('@iebdc/assets/images/tax/承受方list.png')" alt="" />
                <span>承受方缴税信息</span>
              </div>
              <el-table class="el-table-yx" :data="qlrList" :header-cell-style="{ fontSize: '300', color: '#555' }">
                <el-table-column prop="taxpayer" label="姓名"></el-table-column>
                <el-table-column prop="taxType" label="税费种类"> </el-table-column>
                <el-table-column prop="taxSubject" width="120" label="税费科目名称"> </el-table-column>
                <el-table-column prop="payee" label="收费人员"></el-table-column>
                <el-table-column prop="taxBillYear" width="180" label="税票所属年度"> </el-table-column>
                <el-table-column prop="taxPayment" width="120" label="实收金额(元)"> </el-table-column>
                <el-table-column prop="taxPaymentCertificate" width="120" label="完税凭证号码"> </el-table-column>
                <el-table-column prop="taxAmount" width="120" label="计税金额(元)"> </el-table-column>
                <el-table-column prop="taxRate" label="税率"> </el-table-column>
                <el-table-column prop="taxPayable" label="应纳税额"> </el-table-column>
                <el-table-column prop="exemptions" label="减免税额"> </el-table-column>
                <el-table-column
                  prop="reductionReason"
                  label="减税原因"
                  :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column prop="contractCode" width="120" label="合同编号"> </el-table-column>
              </el-table>
            </div>
            <div v-if="ywrList">
              <div class="zydj-block">
                <!-- <i class="el-icon-user-solid"></i> -->
                <img :src="require('@iebdc/assets/images/tax/转让方list.png')" alt="" />
                <span>转让方缴税信息</span>
              </div>
              <el-table class="el-table-yx" :data="ywrList" :header-cell-style="{ fontSize: '300', color: '#555' }">
                <el-table-column prop="taxpayer" label="姓名"></el-table-column>
                <el-table-column prop="taxType" label="税费种类"> </el-table-column>
                <el-table-column prop="taxSubject" width="120" label="税费科目名称"> </el-table-column>
                <el-table-column prop="payee" label="收费人员"></el-table-column>
                <el-table-column prop="taxBillYear" width="180" label="税票所属年度"> </el-table-column>
                <el-table-column prop="taxPayment" width="120" label="实收金额(元)"> </el-table-column>
                <el-table-column prop="taxPaymentCertificate" width="120" label="完税凭证号码"> </el-table-column>
                <el-table-column prop="taxAmount" width="120" label="计税金额(元)"> </el-table-column>
                <el-table-column prop="taxRate" label="税率"> </el-table-column>
                <el-table-column prop="taxPayable" label="应纳税额"> </el-table-column>
                <el-table-column prop="exemptions" label="减免税额"> </el-table-column>
                <el-table-column
                  prop="reductionReason"
                  label="减税原因"
                  :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column prop="contractCode" width="120" label="合同编号"> </el-table-column>
              </el-table>
            </div>
          </div>
          <div v-if="zydjData.flag">
            <div class="zydj-block bh">
              <span>驳回信息</span>
            </div>
            <div class="bh-block">
              <div class="bh-status">
                <span>
                  <img :src="require('@iebdc/assets/images/tax/申报状态.png')" alt="" />
                  <p>申报状态:</p>
                </span>
                <div>{{ zydjData.bhxxDto.sbStatue }}</div>
              </div>
              <div class="bh-reason">
                <span>
                  <img :src="require('@iebdc/assets/images/tax/驳回原因.png')" alt="" />
                  <p>驳回原因:</p>
                </span>
                <div v-if="zydjData.bhxxDto.errorReason">{{ zydjData.bhxxDto.errorReason }}</div>
                <div v-else>暂无信息</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </e-dialog>
    <div class="dia-self">
      <el-dialog title="提示" :visible.sync="openQrInfo.visible" width="38%" height="90%">
        <table class="yxtable taxtable" style="border: 0">
          <tr class="tip-text">
            <td colspan="3">
              尊敬的纳税人，如您未下载
              <span class="bold">新疆税务APP</span>
              ，请通过扫描下方二维码下载。
              <!-- 下载后可通过首页【扫一扫】功能扫描转让方二维码进行申报缴税，并再次扫描受让方二维码缴税。 -->
            </td>
          </tr>
          <tr>
            <th>
              <div class="dwn-block">
                <span>安卓版APP下载</span>
                <img :src="require('@iebdc/assets/images/tax/andriod2x.png')" alt="" />
              </div>
            </th>
            <th></th>
            <th>
              <div class="dwn-block dwn-block-blue">
                <span>ios版APP下载</span>
                <img class="dwn-ios" :src="require('@iebdc/assets/images/tax/ios2x.png')" alt="" />
              </div>
            </th>
          </tr>
          <tr class="dwn">
            <td>
              <el-image
                class="img-warpper"
                :src="require('@iebdc/assets/images/android正式.png')"
                style="width: 160px; height: 160px"
              >
              </el-image>
            </td>
            <td></td>
            <td>
              <el-image
                class="img-warpper"
                :src="require('@iebdc/assets/images/ios正式.png')"
                style="width: 160px; height: 160px"
              >
              </el-image>
            </td>
          </tr>
          <tr>
            <!-- <th>转让方：{{ currentInfo.ywr }}</th> -->
            <th>
              <img class="step" :src="require('@iebdc/assets/images/tax/step1.png')" alt="" />
              请使用<span class="bold">新疆税务APP</span>-首页【扫一扫】扫面下方<span class="bold">转让方二维码</span
              >进行申报缴税
            </th>
            <th>
              <img :src="require('@iebdc/assets/images/tax/right1x.png')" alt="" />
            </th>
            <!-- <th>承受方：{{ currentInfo.qlr }}</th> -->
            <th>
              <img class="step" :src="require('@iebdc/assets/images/tax/step2.png')" alt="" />
              再次扫描下方<span class="bold">受让方二维码</span>缴税
            </th>
          </tr>
          <tr>
            <td>
              <img class="icon" :src="require('@iebdc/assets/images/tax/转让方.png')" alt="" />
              <div class="imgself-block">
                <div>
                  <span>转让方：{{ currentInfo.ywr }}</span>
                </div>
                <el-image class="img-warpper imgself" :src="ywrUrl" style="width: 160px; height: 160px"> </el-image>
              </div>
            </td>
            <td>
              <img :src="require('@iebdc/assets/images/tax/right2x.png')" alt="" />
            </td>
            <td>
              <img class="icon" :src="require('@iebdc/assets/images/tax/承受方.png')" alt="" />
              <div class="imgself-block">
                <div>
                  <span>承受方：{{ currentInfo.qlr }}</span>
                </div>
                <el-image class="img-warpper imgself" :src="qlrUrl" style="width: 160px; height: 160px"> </el-image>
              </div>
            </td>
          </tr>
        </table>

        <span slot="footer" class="dialog-footer">
          <el-button @click="openQrInfo.visible = false">取 消</el-button>
          <el-button type="primary" @click="openQrInfo.visible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getTaxInfoList, queryTaxFinish, getTaxQrCode, quryJkxxOrBhxx } from 'iebdc/api/my-tax.js';
import utils from '@/utils/utils';
import hasPermission from '@iebdc/mixin/hasPermission';
import processInfo from '@iebdc/mixin/getProcessInfo';
import { localDataUser } from 'src/pages/iebdc/utils/local-data';
export default {
  name: 'taxList',
  mixins: [hasPermission, processInfo],
  props: ['stateBoxData', 'dictype'],
  data() {
    return {
      andUrl: '../../../../assets/images/android.png',
      iosUrl: '../../../../assets/images/ios.png',
      qlrUrl: '',
      ywrUrl: '',
      rowInfo: {},
      openRightInfo: {
        title: '完税详情',
        width: '1100px',
        visible: false,
        hasConfirm: false,
        hasCancel: false,
        hasBtn: true,
      },
      openZYDJInfo: {
        title: '完税详情',
        width: '1200px',
        height: '1200px',
        visible: false,
        hasConfirm: false,
        hasCancel: false,
        hasBtn: true,
      },
      openQrInfo: {
        title: '二维码详情',
        width: '1100px',
        visible: false,
        hasConfirm: false,
        hasCancel: false,
        hasBtn: true,
      },
      wsxx: {},
      loading: false,
      params: {
        userId: localDataUser.get().id,
        // taxPushStatus: 1,
        nSize: 10,
      },
      showDetail: false,
      btnsConfig: {
        clearBtnText: '清除',
        showResetBtn: true,
        showExportBtn: false,
        showMoreBtn: false,
      },
      openAddBtns: [
        {
          label: '关闭',
          class: 'public-el-button-default',
          method: () => {
            this.openRightInfo.visible = false;
          },
          show: true,
        },
      ],
      openZYDJBtns: [
        {
          label: '关闭',
          class: 'public-el-button-default',
          method: () => {
            this.openZYDJInfo.visible = false;
            this.qlrList = [];
            this.ywrList = [];
          },
          show: true,
        },
      ],
      openQrBtns: [
        {
          label: '关闭',
          class: 'public-el-button-default',
          method: () => {
            this.openQrInfo.visible = false;
          },
          show: true,
        },
      ],
      filterGroup: [
        { type: 'input', label: '业务号', placeholder: '业务号', value: 'wwywh' },
        {
          label: '申请时间',
          type: 'dateRange',
          centerLabel: false,
          options: [
            {
              key: 'beginTime',
              value: '',
              valueformat: 'yyyy-MM-dd',
              placeholder: '申请时间起',
            },
            {
              key: 'endTime',
              value: '',
              valueformat: 'yyyy-MM-dd',
              placeholder: '申请时间止',
            },
          ],
        },
      ],
      tableList: [],
      columns: [
        {
          width: '200px',
          fixed: true,
          prop: 'ywh',
          label: '业务号',
          align: 'center',
        },
        {
          width: '180px',
          prop: 'djlx',
          label: '登记类型',
          align: 'center',
        },
        {
          width: '180px',
          prop: 'ywmc',
          label: '业务名称',
          align: 'center',
        },
        {
          width: '180px',
          prop: 'taxPushStatusToTxt',
          label: '推送状态',
          align: 'center',
        },
        {
          width: '160px',
          prop: 'qlr',
          label: '权利人',
          align: 'center',
        },
        {
          width: '160px',
          prop: 'ywr',
          label: '义务人',
          align: 'center',
        },
        {
          width: '100px',
          prop: 'sqsj',
          align: 'center',
          label: '申请时间',
          type: 'date',
          formatter: (scope) => {
            return utils.dateFtt(scope.ctime || scope.sqsj, 'yyyy-MM-dd');
          },
        },
      ],
      operates: {
        //表格操作栏
        width: '210px',
        fixed: 'right',
        align: 'center',
        list: [
          {
            show: true,
            disabled: (row) => {
              // if (row.step === 'NETAPPLYING' || row.step === 'VERIFICATION' || row.step === 'VERIFYSUCCESS') {
              //   return true;
              // }
            },
            spanType: 'button',
            id: '1',
            label: '查看',
            type: 'text',
            color: 'primary',
            size: 'medium',
            method: (key, row) => {
              // this.openRightInfo.visible = true;
              this.getDetail(row);
            },
            showCallback: () => {
              return true;
            },
          },
          {
            show: true,
            disabled: (row) => {},
            spanType: 'button',
            id: '2',
            label: '缴税',
            type: 'text',
            color: 'primary',
            size: 'medium',
            method: (key, row) => {
              this.getQrCode(row);
            },
            showCallback: (row) => {
              if (
                row.ywmc === '转移登记' &&
                row.taxStatus !== 4 &&
                row.taxPushStatus !== '0' &&
                row.taxPushStatus !== null
              ) {
                return true;
              }
            },
          },
          {
            show: true,
            disabled: (row) => {},
            spanType: 'button',
            id: '3',
            label: '已缴税',
            type: 'text',
            color: 'primary',
            size: 'medium',
            method: (key, row) => {},
            showCallback: (row) => {
              if (row.ywmc === '转移登记' && row.taxStatus === 4) {
                return true;
              }
            },
          },
        ],
      },
      options: {
        // 每页数据数
        pageSize: 10,
        // 当前页码
        currentPage: 1,
        loading: true,
        mutiSelect: false,
        hasIndex: false,
        // marginTop: '10px',
        maxHeight: '5000px',
        showTip: false,
      },
      flowCodes: ['N100201', 'N200104', 'F200104', 'F100201'],
      currentInfo: {},
      zydjDataList: [],
      qlrName: '',
      ywrName: '',
      qlrList: [],
      ywrList: [],
    };
  },
  computed: {},
  watch: {},

  methods: {
    // 初始化数据
    init() {
      this.params = {
        nCurrent: 0,
        userId: localDataUser.get().id,
        nSize: 10,
      };
      let a = this.flowCodes.join(',');
      this.params.flowCodes = a;
      this.query();
    },
    query() {
      this.loading = true;
      this.showDetail = false;
      let queryData = this.params;
      getTaxInfoList(queryData).then((res) => {
        this.loading = false;
        if (res.success && res.data) {
          //初始化缴费状态
          res.data.page.forEach((element) => {
            element.isShowPay = false;
            element.billNo = '-';
            // 推送状态转文字
            element.taxPushStatus === '1'
              ? (element.taxPushStatusToTxt = '成功')
              : element.taxPushStatus === '0'
              ? (element.taxPushStatusToTxt = '失败')
              : (element.taxPushStatusToTxt = '--');
          });
          this.$refs.myApplicationRef.setTableList(res.data.page);
          this.$refs.myApplicationRef.setTotal(res.data.recordCount);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 查询
    search(queryParams) {
      console.log(this.params);
      this.params = Object.assign(this.params, queryParams, { nCurrent: 0 });
      console.log(this.params);
      this.query();
      this.$refs.myApplicationRef.setCurrentPage(1);
    },
    // 清除(重置)
    reset() {
      //将 第一个select 置为默认显示的值
      this.filterGroup[0].value = 1;
      this.init();
      this.$refs.myApplicationRef.setCurrentPage(1);
    },
    // 翻页
    afterCurrentPageClickHandle(val) {
      console.log(val);
      this.params.nCurrent = val - 1;
      this.query();
    },
    getQrCode(row) {
      if (Date.now() - row.sqsj < 120000) {
        this.$message.warning('请稍后再试');
        return;
      }

      this.currentInfo = row;
      this.openQrInfo.visible = true;
      let qlrParams = {
        //买方 受让方
        ywsbh: row.ywh,
        mmsflx: 'vendee',
        // code: '63524c39063e0a313428a58c',
        code: row.qlrIds ? row.qlrIds.split(',')[0] : '',
      };
      let ywrParams = {
        //卖方 转让方
        ywsbh: row.ywh,
        mmsflx: 'vender',
        // code: '63524c63063e0a313428a58f',
        code: row.ywrIds ? row.ywrIds.split(',')[0] : '',
      };
      if (row.sqrlx !== 'NORMAL') {
        window.location.href = this.$decryptByDES(window.CONFIG.jsdz) + '&code=' + row.qlrIds.split(',')[0];
        return;
      }

      getTaxQrCode(qlrParams).then((res) => {
        if (res.success) {
          this.qlrUrl = res.message;
        } else {
          this.$message.warning(res.message);
        }
      });
      getTaxQrCode(ywrParams).then((res) => {
        if (res.success) {
          this.ywrUrl = res.message;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 删除数组指定元素返回新数组且不改变原数组
    getDetail(row) {
      let params = {
        wwywh: row.ywh,
      };
      this.rowInfo = row;
      console.log(row);
      if (row.ywmc === '转移登记') {
        quryJkxxOrBhxx(params).then((res) => {
          if (res.length > 0) {
            this.zydjDataList = res;
            this.qlrList = [];
            this.ywrList = [];
            console.log(this.zydjDataList);
            this.zydjDataList.forEach((item) => {
              if (!item.flag) {
                item.jkxxDtos.forEach((data) => {
                  if (this.rowInfo.qlr.split(',').indexOf(data.taxpayer) > -1) {
                    // this.qlrName = '承受方';
                    this.qlrList.push(data);
                  }
                  if (this.rowInfo.ywr.split(',').indexOf(data.taxpayer) > -1) {
                    // this.ywrName = '转让方';
                    this.ywrList.push(data);
                  }
                });
              }
            });
            this.openZYDJInfo.visible = true;
          } else {
            this.$message.warning('暂无缴税信息！');
          }
        });
      } else {
        queryTaxFinish(params).then((res) => {
          if (res.success) {
            this.wsxx = res.data;
            this.openRightInfo.visible = true;
          } else {
            this.$message.warning(res.message);
          }
        });
      }
    },
    //返回
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
/deep/ .filter-item {
  margin: 0 !important;
}

.jdcx-center {
  margin-top: 20px;

  label {
    font-size: 16px;
    float: left;
    line-height: 80px;
    margin-right: 30px;
    font-weight: 700;
  }

  .jdcx-item {
    /*width: 130px;*/
    height: 80px;

    .jd-jt {
      width: 50px;
      line-height: 80px;
      text-align: center;
    }

    .jd-img {
      width: 80px;
      height: 80px;
      font-size: 13px;
      background-color: #f3f3f3;
      border-radius: 5px;
      text-align: center;

      div {
        height: 48px;
        margin-bottom: 3px;
      }
    }
  }
}
/deep/ .e-table {
  position: relative !important;
  .el-table__body-wrapper {
    height: auto !important;
  }
}
.table {
  width: 100%;
  border-collapse: collapse;
  td {
    padding: 0 10px;
    font-size: 14px;
    color: #383838;
    border: 1px solid #dddddd;
    text-align: left;
    height: 35px;
  }
  .labelTip {
    width: 170px;
    background: #e5e9f2;
    text-align: right;
  }
}

.yxtable {
  border: 1px solid rgb(234, 238, 241);
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
  th {
    width: 223px;
    border: 1px solid rgb(234, 238, 241);
  }
  td {
    width: 223px;
    text-align: center;
    height: 50px;
    border: 1px solid rgb(234, 238, 241);
    // padding: 20px;
  }
}
.el-table-yx {
  // overflow-x: scroll;
}

.el-table-yx {
  width: 100%;
  font-size: 14px;
  overflow: auto;
  /deep/.el-table__cell {
    height: 53px;
    text-align: center;
    background-color: #fafafa;
  }
  /deep/.el-table__empty-text {
    line-height: 48px;
  }
  /deep/.el-table__empty-block {
    height: 48px;
    min-height: 48px;
  }
}
// horizontal
// .el-table-yx::-webkit-scrollbar:horizontal {
//   /*滚动条整体样式*/
//   width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
//   height: 10px;
// }
// .el-table-yx::-webkit-scrollbar-thumb {
//   /*滚动条里面小方块*/
//   border-radius: 10px;
//   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//   background: #ccc;
// }
// .el-table-yx::-webkit-scrollbar-track:horizontal {
//   /*滚动条里面轨道*/
//   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//   border-radius: 10px;
//   background: #ededed;
// }
.taxtable th {
  border: 1px solid #eaeef1;
  padding: 20px 0;
}
.icon {
  transform: scale(0.7);
  position: absolute;
  margin: 20p;
  top: -3px;
  right: -9px;
}
.imgself-block {
  border-radius: 8px;
  border: 1px solid #ebebeb;
  padding: 10px 10px 0px 10px;
  div {
    margin: 0 0 10px 0;
    span {
      margin-left: -94px;
    }
  }
  /deep/ .imgself .el-image__inner {
    transform: scale(1.16) !important;
  }
}
/deep/.el-icon-close:before {
  color: #536dcc;
}
/deep/ .dia-self .el-dialog {
  .el-dialog__body {
    padding: 25px 50px;
    min-height: 720px !important;
    overflow-y: auto;
    table {
      border: none !important;
      td {
        position: relative;
        border: none !important;
      }
      th {
        border: none !important;
        width: 300px;
      }
    }
  }
  .el-dialog__footer {
    margin-top: -50px;
  }
  .step {
    position: relative;
    top: 3px;
  }
  .img-warpper {
    border: 5px solid #eaeef1;
  }
  th {
    height: 36px;
    line-height: 36px;
    font-weight: 500;
    line-height: 18px;
  }
  .dwn-block {
    margin: 0 auto;
    width: 197px;
    height: 36px;
    background: #e7f8ef;
    box-shadow: 0px 0px 6px 1px rgba(114, 114, 114, 0.12);
    border-radius: 4px 4px 4px 4px;
    span {
      position: relative;
      top: -13px;
      left: 44px;
    }
    img {
      margin: 19px 0 0 69px;
    }
    .dwn-ios {
      margin-right: -20px;
      margin-top: 17px;
    }
  }
  .dwn-block-blue {
    background: #f5f6f7;
  }
}
.tip-text td {
  border: 1px solid #eaeef1;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  padding: 10px;
  text-indent: 20px;
  line-height: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.bold {
  font-weight: bold;
}
.zydj-block {
  margin: 20px 0;
  i {
    color: #536dcc;
  }
  span {
    margin-left: 10px;
    font-weight: 550;
    font-size: 15px;
  }
}
.bh {
  text-align: center;
  font-weight: bold;
  color: #333333;
}
.bh-block {
  p {
    font-weight: bold;
    color: #333333;
    font-size: 14px;
    display: inline-block;
    position: relative;
    margin-left: 5px;
    top: -2px;
  }
  .bh-status {
    margin: 20px 0;
    div {
      margin: 20px 0;
      width: 134px;
      height: 38px;
      background: #fff2f2;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      font-size: 14px;
      font-weight: 400;
      color: #ef0000;
      line-height: 38px;
      text-align: center;
    }
  }
  .bh-reason {
    margin: 20px 0;
    div {
      margin: 20px 0;
      width: 100%;
      height: 130px;
      background: #fafafa;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      padding: 20px;
    }
  }
}

/deep/ .zydj-dia .el-dialog .el-dialog__body {
  max-height: 440px !important;
  padding: 35px 50px;
  overflow-y: auto;
}
</style>
