<template>
  <div class="express-box">
    <el-tabs v-model="activeName">
      <el-tab-pane label="我寄的" name="first">
        <div>
          <div class="top">
            <e-search
              ref="search"
              style="padding: 8px 0; height: 70px"
              :filterGroup="filterGroup1"
              :btnsConfig="btnsConfig"
              :EDialogConfig="EDialogConfig"
              @search="search1"
              @reset="reset(1)"
            ></e-search>
            <e-table
              ref="myExpress"
              :loading="loading"
              :tableList="tableList"
              :columns="columns1"
              :operates="operates"
              :options="options"
              @afterCurrentPageClick="afterCurrentPageClickHandle1"
            ></e-table>
            <el-dialog
              title="提示"
              :visible.sync="dialogVisible"
              width="60%"
              :append-to-body="true"
              :before-close="handleClose"
            >
              <div class="dia-wrapper">
                <div>
                  <el-button type="primary" class="el-icon-box" round>揽收结果</el-button>
                  <div class="dia-result">
                    <div v-if="typeof pickUpResult == Object">
                      <p>
                        揽收状态：
                        {{
                          pickUpResult.status == 1
                            ? '成功'
                            : pickUpResult.status == 0
                            ? '失败(拒收)'
                            : pickUpResult.status == 2
                            ? '未揽收'
                            : '---'
                        }}
                      </p>
                      <p>邮费： {{ pickUpResult.postage }}元</p>
                      <p>重量： {{ pickUpResult.weight }}</p>
                      <p>保价金额： {{ pickUpResult.insuranceamount }}元</p>
                    </div>
                    <div v-else>
                      <el-empty description="" :image-size="100"></el-empty>
                      <p>{{ pickUpResult ? pickUpResult : '接口返回信息有误' }}</p>
                    </div>
                  </div>
                </div>
                <div class="trace-block">
                  <el-button type="primary" class="el-icon-truck" round>物流信息</el-button>
                  <div v-if="activeLength !== 0">
                    <el-steps :active="activeLength" align-center>
                      <el-step
                        v-for="(step, i) in traceResult.responseItems"
                        :key="i"
                        :title="step.opOrgProvName + step.opOrgCity"
                        :description="step.opDesc + '\r\n' + step.opTime"
                        class="step-block"
                      >
                      </el-step>
                    </el-steps>
                  </div>
                  <div v-else>
                    <el-empty description="" :image-size="100"></el-empty>
                    <p style="margin: 20px 10px">
                      {{ traceResult.errorDesc ? traceResult.errorDesc : '暂无信息' }}
                    </p>
                  </div>
                </div>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirm(0)">确 定</el-button>
              </span>
            </el-dialog>
            <el-dialog
              title="提示"
              :visible.sync="cancelVisible"
              width="60%"
              :append-to-body="true"
              :before-close="handleClose"
            >
              <div class="dia-wrapper">
                <span>取消上门揽件原因： </span>
                <el-select v-model="cancelValue" placeholder="请选择">
                  <el-option v-for="item in cancelOptions" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button
                  @click="
                    cancelVisible = false;
                    cancelValue = null;
                  "
                  >取 消</el-button
                >
                <el-button type="primary" :disabled="!cancelValue" @click="confirm(1)">确 定</el-button>
              </span>
            </el-dialog>
            <el-dialog
              title="提示"
              :visible.sync="changeVisible"
              width="60%"
              :append-to-body="true"
              :before-close="handleClose"
            >
              <div class="dia-wrapper">
                <span>预约上门揽件时间： </span>
                <el-date-picker
                  class="detail-ipt"
                  style="width: 400px"
                  type="datetimerange"
                  v-model="appointMentDate"
                  range-separator="至"
                  start-placeholder="预约开始时间"
                  end-placeholder="预约结束时间"
                  clearable
                  placeholder="datetime"
                  align="left"
                  :picker-options="pickerOptions"
                  :default-time="['10:00:00', '18:00:00']"
                ></el-date-picker>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="changeVisible = false">取 消</el-button>
                <el-button type="primary" :disabled="!appointMentDate" @click="confirm(2)" :loading="isBack"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
            <!-- 取消订单 -->
            <el-dialog
              title="提示"
              :visible.sync="cancelOrderVisible"
              width="60%"
              :append-to-body="true"
              :before-close="handleClose"
            >
              <div class="dia-wrapper">
                <span>取消订单原因： </span>
                <el-input v-model="cancelOrderReason" placeholder="取消订单原因"></el-input>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button
                  @click="
                    cancelOrderVisible = false;
                    cancelOrderReason = null;
                  "
                  >取 消</el-button
                >
                <el-button type="primary" :disabled="!cancelOrderReason" @click="confirm(1)">确 定</el-button>
              </span>
            </el-dialog>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="我收的" name="second">
        <div>
          <div class="top">
            <e-search
              ref="search"
              style="padding: 8px 0; height: 70px"
              :filterGroup="filterGroup2"
              :btnsConfig="btnsConfig"
              :EDialogConfig="EDialogConfig"
              @search="search2"
              @reset="reset(2)"
            ></e-search>
            <e-table
              ref="myApplicationRef"
              :loading="loading"
              :tableList="tableList"
              :columns="columns2"
              :operates="operates"
              :options="options"
              @afterCurrentPageClick="afterCurrentPageClickHandle2"
            ></e-table>
            <el-dialog
              title="订单详情"
              :visible.sync="dialogSecondVisible"
              width="60%"
              :append-to-body="true"
              :before-close="handleClose"
            >
              <div class="dia-wrapper">
                <div>
                  <el-button type="primary" class="el-icon-box" round>揽收结果</el-button>
                  <div class="dia-result">
                    <div v-if="typeof pickUpResult == Object">
                      <p>
                        揽收状态：
                        {{
                          pickUpResult.status == 1
                            ? '成功'
                            : pickUpResult.status == 0
                            ? '失败(拒收)'
                            : pickUpResult.status == 2
                            ? '未揽收'
                            : '---'
                        }}
                      </p>
                      <p>邮费： {{ pickUpResult.postage }}元</p>
                      <p>重量： {{ pickUpResult.weight }}</p>
                      <p>保价金额： {{ pickUpResult.insuranceamount }}元</p>
                    </div>
                    <div v-else>
                      <el-empty description="" :image-size="100"></el-empty>
                      <p>{{ pickUpResult ? pickUpResult : '接口返回信息有误' }}</p>
                    </div>
                  </div>
                </div>
                <div class="trace-block">
                  <el-button type="primary" class="el-icon-truck" round>物流信息</el-button>
                  <div v-if="activeLength !== 0">
                    <el-steps :active="activeLength" align-center>
                      <el-step
                        v-for="(step, i) in traceResult.responseItems"
                        :key="i"
                        :title="step.opOrgProvName + step.opOrgCity"
                        :description="step.opDesc + '\r\n' + step.opTime"
                        class="step-block"
                      >
                      </el-step>
                    </el-steps>
                  </div>
                  <div v-else>
                    <el-empty description="" :image-size="100"></el-empty>
                    <p style="margin: 20px 10px">
                      {{ traceResult.errorDesc ? traceResult.errorDesc : '暂无信息' }}
                    </p>
                  </div>
                </div>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogSecondVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirm(0)">确 定</el-button>
              </span>
            </el-dialog>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import applicantEmsService from '@/pages/iebdc/api/apply/applicant-ems';
import utils from '@/utils/utils';
import hasPermission from '@iebdc/mixin/hasPermission';
import processInfo from '@iebdc/mixin/getProcessInfo';

export default {
  mixins: [hasPermission, processInfo],
  components: {},
  props: {},
  data() {
    return {
      activeName: 'first',
      userId: JSON.parse(localStorage.getItem('IEBDC_USER_DATA')).userId,
      dialogVisible: false,
      dialogSecondVisible: false,
      cancelVisible: false,
      changeVisible: false,
      cancelOrderVisible: false,
      isBack: false,
      pickUpResult: {},
      traceResult: {},
      arriveCity: '',
      appointMentDate: '',
      cancelOrderReason: '',
      cancelPickData: {},
      loading: false,
      activeLength: 0,
      pickerOptions: {
        disabledDate: (time) => {
          return this.dealDisabledDate(time);
        },
      },
      cancelOptions: [
        {
          value: '13',
          label: '上门揽收不及时放弃',
        },
        {
          value: '19',
          label: '客户自交寄',
        },
        {
          value: '20',
          label: '客户转交其他公司',
        },
        {
          value: '16',
          label: '已联系客户并确认重复下单',
        },
        {
          value: '8',
          label: '超禁限',
        },
        {
          value: '9',
          label: '超规格',
        },
        {
          value: '28',
          label: '客户取消订单',
        },
        {
          value: '12',
          label: '因资费原因放弃',
        },
        {
          value: '22',
          label: '客户更改取件地址',
        },
        {
          value: '23',
          label: 'EMS到达时限慢',
        },
        {
          value: '24',
          label: '测试单',
        },
        {
          value: '25',
          label: '客户要求到付',
        },
        {
          value: '26',
          label: '其他',
        },
        {
          value: '30',
          label: '计划有变',
        },
        {
          value: '31',
          label: '误操作',
        },
        {
          value: '32',
          label: '邮件时限',
        },
        {
          value: '33',
          label: '资费原因',
        },
        {
          value: '34',
          label: '上门不及时',
        },
        {
          value: '35',
          label: '服务态度不好',
        },
        {
          value: '99',
          label: '揽收员不存在',
        },
      ],
      cancelValue: null,
      params1: {
        nCurrent: 0,
        nSize: 10,
        userId: JSON.parse(localStorage.getItem('IEBDC_USER_DATA')).userId,
        receiverName: null,
        senderName: null,
        ywh: null,
        spk: true,
      },
      params2: {
        nCurrent: 0,
        nSize: 10,
        userId: JSON.parse(localStorage.getItem('IEBDC_USER_DATA')).userId,
        receiverName: null,
        senderName: null,
        ywh: null,
        spk: false,
      },
      // 筛选 业务号 寄件方 收件方
      filterGroup1: [
        {
          type: 'input',
          dicType: '业务号',
          label: '业务号',
          placeholder: '业务号',
          key: 'ywh',
          value: '',
        },
        {
          type: 'input',
          dicType: '寄件方',
          label: '寄件方',
          placeholder: '寄件方',
          key: 'senderName',
          value: '',
        },
        {
          type: 'input',
          dicType: '收件方',
          label: '收件方',
          placeholder: '收件方',
          key: 'receiverName',
          value: '',
        },
      ],
      filterGroup2: [
        {
          type: 'input',
          dicType: '业务号',
          label: '业务号',
          placeholder: '业务号',
          key: 'ywh',
          value: '',
        },
        {
          type: 'input',
          dicType: '寄件方',
          label: '寄件方',
          placeholder: '寄件方',
          key: 'senderName',
          value: '',
        },
        {
          type: 'input',
          dicType: '收件方',
          label: '收件方',
          placeholder: '收件方',
          key: 'receiverName',
          value: '',
        },
      ],
      EDialogConfig: {
        visible: false, //默认不显示更多
        maxLength: 5,
        name: 'applicationMain',
      },
      btnsConfig: {
        clearBtnText: '清除',
        showResetBtn: true,
        showExportBtn: false,
        showMoreBtn: false,
      },
      tableList: [],
      columns1: [
        {
          width: '200px',
          fixed: true,
          prop: 'ywh',
          label: '业务号',
          align: 'center',
        },
        {
          width: '200px',
          prop: 'waybillResult',
          label: '运单号',
          align: 'center',
        },
        {
          width: '180px',
          prop: 'senderName',
          label: '寄件方',
          align: 'center',
        },
        {
          width: '180px',
          prop: 'senderAddress',
          label: '寄件方地址',
          align: 'center',
        },
        {
          width: '180px',
          prop: 'receiverName',
          label: '收件方',
          align: 'center',
        },
        {
          width: '180px',
          prop: 'receiverAddress',
          label: '收件方地址',
          align: 'center',
        },
      ],
      columns2: [
        {
          width: '200px',
          fixed: true,
          prop: 'ywh',
          label: '业务号',
          align: 'center',
        },
        {
          width: '200px',
          prop: 'waybillResult',
          label: '运单号',
          align: 'center',
        },
        {
          width: '200px',
          prop: 'created_time',
          align: 'center',
          label: '申请时间',
        },
        {
          width: '180px',
          prop: 'senderName',
          label: '寄件方',
          align: 'center',
        },
        {
          width: '180px',
          prop: 'senderAddress',
          label: '寄件方地址',
          align: 'center',
        },
        {
          width: '180px',
          prop: 'receiverName',
          label: '收件方',
          align: 'center',
        },
        {
          width: '180px',
          prop: 'receiverAddress',
          label: '收件方地址',
          align: 'center',
        },
      ],
      operates: {
        //表格操作栏
        width: '210px',
        fixed: 'right',
        align: 'center',
        list: [
          {
            show: true,
            spanType: 'button',
            id: '1',
            label: '详情',
            type: 'text',
            color: 'primary',
            size: 'medium',
            method: (key, row) => {
              if (row.spk == true) {
                this.dialogVisible = true;
              } else {
                this.dialogSecondVisible = true;
              }
              this.getDetail(row);
            },
            showCallback: () => {
              return true;
            },
          },
          {
            show: true,
            spanType: 'button',
            id: '2',
            label: '取消订单',
            type: 'text',
            color: 'danger',
            size: 'medium',
            method: (key, row) => {
              this.cancelPickData = row;
              this.cancelOrderVisible = true;
            },
            showCallback: (row) => {
              if (row.spk == true) {
                return true;
              } else {
                return false;
              }
            },
          },
          {
            show: true,
            spanType: 'button',
            id: '3',
            label: '取消上门揽件',
            type: 'text',
            color: 'warning',
            size: 'medium',
            method: (key, row) => {
              this.cancelVisible = true;
              this.cancelPickData = row;
            },
            showCallback: (row) => {
              // if (row.spk == true && row.pickUpTime) {
              //   return true;
              // } else {
              //   return false;
              // }
              return false;
            },
          },
          {
            show: true,
            spanType: 'button',
            id: '4',
            label: '更改揽件时间',
            type: 'text',
            color: 'warning',
            size: 'medium',
            method: (key, row) => {
              this.changeVisible = true;
              this.cancelPickData = row;
              this.appointMentDate = [new Date(row.startPickTime), new Date(row.endPickTime)];
            },
            showCallback: (row) => {
              if (row.spk == true && row.pickUpTime) {
                return true;
              } else {
                return false;
              }
            },
          },
        ],
      },
      options: {
        // 每页数据数
        pageSize: 10,
        // 当前页码
        currentPage: 1,
        loading: true,
        mutiSelect: false,
        hasIndex: false,
        // marginTop: '10px',
        maxHeight: '5000px',
        showTip: false,
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.query(1);
    this.query(2);
  },
  methods: {
    // 初始化数据
    init(index) {
      if (index == 1) {
        this.query(1);
      } else {
        this.query(2);
      }
    },
    query(type) {
      this.loading = true;
      this.showDetail = false;
      if (type == 1) {
        applicantEmsService.getOrdersByUserId(this.params1).then((res) => {
          this.loading = false;
          if (res.success && res.data) {
            const pageData0 = res.data.page;
            pageData0.forEach((item) => {
              console.log(item.pickUpParam);
              item.senderName = item.pickUpParam.sender.name;
              item.senderAddress = item.pickUpParam.sender.address;
              item.senderPhone = item.pickUpParam.sender.phone;
              item.receiverName = item.pickUpParam.receiver.name;
              item.receiverAddress = item.pickUpParam.receiver.address;
              item.receiverPhone = item.pickUpParam.receiver.phone;
              item.waybillResult = JSON.parse(item.result).mailNo;
              item.created_time = utils.dateFtt(item.ctime, 'yyyy-MM-dd hh:mm:ss');
              if (item.pickUpTime) {
                item.startPickTime = item.pickUpTime.split('至')[0];
                item.endPickTime = item.pickUpTime.split('至')[1];
              }
            });
            this.$refs.myExpress.setTableList(pageData0);
            this.$refs.myExpress.setTotal(res.data.recordCount);
          } else {
            this.$message.error(res.message);
          }
        });
      } else if (type == 2) {
        applicantEmsService.getOrdersByUserId(this.params2).then((res) => {
          this.loading = false;
          if (res.success && res.data) {
            const pageData = res.data.page;
            pageData.forEach((item) => {
              item.senderName = item.sender.name;
              item.senderAddress = item.sender.address;
              item.senderPhone = item.sender.phone;
              item.receiverName = item.receiver.name;
              item.receiverAddress = item.receiver.address;
              item.receiverPhone = item.receiver.phone;
              item.waybillResult = JSON.parse(item.result).responses.responseItems.response.waybill_no;
              if (item.pickUpTime) {
                item.startPickTime = item.pickUpTime.split('至')[0];
                item.endPickTime = item.pickUpTime.split('至')[1];
              }
            });
            this.$refs.myApplicationRef.setTableList(pageData);
            this.$refs.myApplicationRef.setTotal(res.data.recordCount);
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    dealDisabledDate(time) {
      if (new Date(time).getTime() < new Date().getTime()) {
        return time.getTime() < Date.now() - 8.64e7;
      }
    },
    // 翻页
    afterCurrentPageClickHandle1(val) {
      this.params1.nCurrent = val - 1;
      this.query(1);
    },
    afterCurrentPageClickHandle2(val) {
      this.params2.nCurrent = val - 1;
      this.query(2);
    },
    // 查询
    search1(queryParams) {
      this.params1 = Object.assign(this.params1, queryParams, { nCurrent: 0 });
      this.query(1);
      this.$refs.myExpress.setCurrentPage(1);
    },
    search2(queryParams) {
      this.params2 = Object.assign(this.params2, queryParams, { nCurrent: 0 });
      this.query(2);
      this.$refs.myApplicationRef.setCurrentPage(1);
    },
    // 清除(重置)
    reset(index) {
      if (index == 1) {
        this.params1.ywh = null;
        this.params1.senderName = null;
        this.params1.receiverName = null;
        this.init(index);
        this.$refs.myExpress.setCurrentPage(1);
      }
      if (index == 2) {
        this.params2.ywh = null;
        this.params2.senderName = null;
        this.params2.receiverName = null;
        this.init(index);
        this.$refs.myApplicationRef.setCurrentPage(1);
      }
    },
    // 查看
    getDetail(row) {
      const detailParams = {
        txLogisticID: row.pickUpParam.logistics_order_no,
      };
      const detailParams1 = {
        orderNo: row.waybillResult,
      };
      //  揽收结果
      applicantEmsService
        .getEmsResult(detailParams)
        .then((res) => {
          this.pickUpResult = res.message;
        })
        .catch((err) => {
          this.$message({
            message: err,
            type: 'warning',
          });
        });
      //  轨迹信息
      applicantEmsService
        .getTrace(detailParams1)
        .then((res) => {
          this.traceResult = res;
          this.activeLength = this.traceResult.responseItems.length;
        })
        .catch((err) => {
          this.$message({
            message: err,
            type: 'warning',
          });
        });
    },
    //
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelValue = null;
          this.cancelOrderReason = null;
          done();
        })
        .catch((_) => {});
    },
    // 弹窗确定
    confirm(index) {
      // 查看详情
      switch (index) {
        case 0:
          this.dialogVisible = false;
          this.dialogSecondVisible = false;
          break;
        case 1:
          // 取消揽件 相当于取消订单
          const cancelPickParams = {
            orderId: this.cancelPickData.id,
            cancelCode: this.cancelValue,
          };
          applicantEmsService.cancelPickUp(cancelPickParams).then((res) => {
            if (res.isSuccess == 'true') {
              this.$message.success('取消订单成功！');
              this.cancelVisible = false;
              this.cancelValue = null;
              this.cancelOrderVisible = false;
              this.cancelOrderReason = null;
              this.query(1);
            } else {
              this.$message.error(res.message);
            }
          });
          break;
        case 2:
          // 修改揽件时间
          if (this.checkTime()) {
            this.isBack = true;
            const changePickParams = {
              orderId: this.cancelPickData.id,
              startTime: utils.dateFtt(this.appointMentDate[0], 'yyyy-MM-dd hh:mm:ss'),
              endTime: utils.dateFtt(this.appointMentDate[1], 'yyyy-MM-dd hh:mm:ss'),
            };
            // 修改预约时间
            applicantEmsService.changeTime(changePickParams).then((res) => {
              this.isBack = false;
              if (res.errorCode == '1') {
                this.$message.success(res.errorMsg);
                this.changeVisible = false;
                this.query(1);
              } else {
                this.$message.error(res.errorMsg);
              }
            });
          }
          break;
        case 3:
          // 取消订单
          const cancelParams = {
            orderId: this.cancelPickData.id,
            orderNo: this.cancelPickData.ywh,
            waybillNo: this.cancelPickData.waybillResult,
            reason: this.cancelOrderReason,
          };
          applicantEmsService.cancelOrder(cancelParams).then((res) => {
            if (res.isSuccess == 'true') {
              this.$message.success('取消订单成功！');
              this.cancelOrderVisible = false;
              this.cancelOrderReason = null;
              this.query(1);
            } else {
              this.$message.error(res.message);
            }
          });
          break;
        default:
          break;
      }
    },
    // 揽件时间验证
    checkTime() {
      const startHour = Number(utils.dateFtt(this.appointMentDate[0], 'yyyy-MM-dd hh:mm:ss').substr(11, 2));
      const endHour = Number(utils.dateFtt(this.appointMentDate[1], 'yyyy-MM-dd hh:mm:ss').substr(11, 2));
      if (startHour < 10 || endHour > 18) {
        this.$message.warning('请确定预约时间在工作时间内！(工作时间：10:00 - 18:00)');
        return false;
      } else if (this.appointMentDate[0].getTime() < new Date().getTime()) {
        this.$message.warning('开始时间不得小于当前时间');
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.express-box h1 {
  margin-bottom: 10px;
}
/deep/ .e-table {
  position: relative !important;
  .el-table__body-wrapper {
    height: auto !important;
  }
}
/deep/ .right-panel {
  margin-top: 0px !important;
}
.dia-wrapper div {
  margin: 20px 0;
  .dia-result {
    margin: 20px 10px;
    p {
      margin: 20px 0;
      display: flex;
      text-align: center;
      flex-direction: column;
      font-size: 14px;
      color: #909399;
    }
  }
  .step-block {
    white-space: pre;
  }
}
.trace-block {
  margin: 20px 10px;
  p {
    margin: 20px 0;
    display: flex;
    text-align: center;
    flex-direction: column;
    font-size: 14px;
    color: #909399;
  }
}
.el-empty {
  padding: 0;
}
/deep/ .el-empty__description p {
  margin: 0;
  display: none !important;
}
</style>
